import { template as template_60715636f3044488abcb25d6064f6242 } from "@ember/template-compiler";
const FKLabel = template_60715636f3044488abcb25d6064f6242(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
