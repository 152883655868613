import { template as template_e6729608693f4afaa5d334ea68c0c90d } from "@ember/template-compiler";
const FKText = template_e6729608693f4afaa5d334ea68c0c90d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
