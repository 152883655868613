import { template as template_226411c77f0d4049810941ed64fab9ed } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_226411c77f0d4049810941ed64fab9ed(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
