import { template as template_347391515d6a4509a7708f43e863ba1e } from "@ember/template-compiler";
const FKControlMenuContainer = template_347391515d6a4509a7708f43e863ba1e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
